import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useAllPrismicDocumentsByTag } from '@prismicio/react';

import { PageHeader, SearchBar } from '@ftrprf/tailwind-components';

import { videoContainsSearchQuery } from '../searchVideos';
import { sortVideosByWeight } from '../sortVideos';
import { videosAreLoaded } from '../videosAreLoaded';

import { VideoItem } from './VideoItem';

export const HelpVideosByTag = () => {
  let { category } = useParams();
  const [otherVideos, setOtherVideos] = useState([]);
  const [helpVideos, setHelpVideos] = useState([]);
  const loadVideos = useAllPrismicDocumentsByTag(category, {
    lang: 'nl-nl',
  });
  const breadcrumbsLinks = [
    { href: '/', name: 'Help', current: true },
    {
      href: generatePath('/tag/:category', { category }),
      name: category,
      current: true,
    },
  ];

  const filterVideos = (value) => {
    if (!helpVideos) return;

    let filteredVideos = helpVideos;

    if (value) {
      const searchString = value.toLowerCase();

      filteredVideos = helpVideos.filter((video) => {
        return videoContainsSearchQuery(video, searchString);
      });
    }

    setOtherVideos(filteredVideos);
  };

  useEffect(() => {
    if (videosAreLoaded(loadVideos)) {
      setHelpVideos(loadVideos[0].sort(sortVideosByWeight));
      setOtherVideos(loadVideos[0].sort(sortVideosByWeight));
    }
  }, [helpVideos, loadVideos, setHelpVideos]);

  return (
    <>
      <PageHeader
        breadcrumbs={breadcrumbsLinks}
        homeInfo={{
          homeLink: 'https://platform.ftrprf.be',
          homeText: 'Platform'
        }}
        title={category}
      />
      <div className="max-w-5/6 mx-auto mt-8">
        <SearchBar
          className=""
          onChange={filterVideos}
          placeholder={'Zoek een hulpvideo'}
        />
        <section className="mb-8">
          <h2 className="w-full h-16 py-4 font-semibold text-xl">Videos</h2>
          <ul className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
            {helpVideos &&
              otherVideos.map((video) => (
                <VideoItem
                  key={video.id}
                  featured={false}
                  video={video}
                  tagPrefix="videoTag"
                />
              ))}
          </ul>
        </section>
      </div>
    </>
  );
};
