import c from '../../utils/c';

import { approvedTags } from '../approvedTags';

export const Tags = ({ tags, keyPrefix }) => {
  const fixedClasses =
    'inline-block  mb-2 mr-3 px-4 py-1 rounded-full text-sm font-semibold';

  return (
    <ul className="list-none">
      {tags.map((tag, tagIndex) => {
        return (
          <li
            key={`${keyPrefix}${tagIndex}-${tagIndex}`}
            className={c(
              fixedClasses,
              approvedTags[tag]
                ? approvedTags[tag].colors
                : approvedTags.default.colors,
            )}
          >
            {tag}
          </li>
        );
      })}
    </ul>
  );
};
