import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import {
  PrismicRichText,
  PrismicText,
  usePrismicDocumentByUID,
} from '@prismicio/react';

import { PageHeader, PageTitle } from '@ftrprf/tailwind-components';

import URLS from '../../utils/constants/urls';

import { Tags } from '../VideoOverview/Tags';
import { videosAreLoaded } from '../videosAreLoaded';

export const HelpVideoDetail = () => {
  let { videoSlug } = useParams();
  const [video, setVideo] = useState();
  const loadVideo = usePrismicDocumentByUID('help_topic', videoSlug);
  const breadcrumbsLinks = [
    {
      href: '/',
      name: 'Help',
      current: false,
    },
    {
      href: generatePath(URLS.HELP_VIDEO, { videoSlug: video?.uid || 'temp' }),
      name: video?.data.title[0].text,
      current: true,
    },
  ];

  useEffect(() => {
    if (videosAreLoaded(loadVideo)) {
      setVideo(loadVideo[0]);
    }
  }, [loadVideo]);

  return (
    <>
      <PageHeader
        breadcrumbs={breadcrumbsLinks}
        homeInfo={{
          homeLink: 'https://platform.ftrprf.be',
          homeText: 'Platform'
        }}
        titleComponent={
          <PageTitle>
            <div className="flex justify-between items-center">
              <PrismicText field={video?.data.title} />
              {video?.tags && <Tags tags={video.tags} prefix="detailTag" />}
            </div>
          </PageTitle>
        }
      />
      <div className="max-w-5/6 mx-auto mt-8 pb-8">
        <div
          className="w-full aspect-w-16 aspect-h-9 bg-gray-100"
          dangerouslySetInnerHTML={{
            __html: video?.data.video.html,
          }}
        />
        <div className="mt-8">
          <PrismicRichText
            field={video?.data.description}
            components={{
              paragraph: ({ children }) => <p className="mb-4">{children}</p>,
            }}
          />
        </div>
      </div>
    </>
  );
};
