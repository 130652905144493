import { useEffect, useState } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';

import { PageHeader, SearchBar } from '@ftrprf/tailwind-components';

import { videoContainsSearchQuery } from '../searchVideos';
import { setTagsForVideos } from '../setTagsForVideos';
import { sortVideosByWeight } from '../sortVideos';
import { splitVideosIntoFeaturedAndOthers } from '../splitVideosIntoFeaturedAndOthers';
import { videosAreLoaded } from '../videosAreLoaded';

import { Category } from './Category';
import { VideoItem } from './VideoItem';
import { VideosSection } from './VideosSection';

export const HelpVideos = () => {
  const [tags, setTags] = useState([]);
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [otherVideos, setOtherVideos] = useState([]);
  const [helpVideos, setHelpVideos] = useState([]);
  const loadVideos = useAllPrismicDocumentsByType('help_topic', {
    lang: 'nl-nl',
  });

  const filterVideos = (value) => {
    if (!helpVideos) return;

    let filteredVideos = helpVideos;

    if (value) {
      const searchString = value.toLowerCase();

      filteredVideos = helpVideos.filter((video) => {
        return videoContainsSearchQuery(video, searchString);
      });
    }

    splitVideosIntoFeaturedAndOthers(
      filteredVideos,
      setFeaturedVideos,
      setOtherVideos,
    );
  };

  useEffect(() => {
    const processVideos = (videos) => {
      if (!videos[0]?.tags) return;
      setTagsForVideos(videos, setTags);
      splitVideosIntoFeaturedAndOthers(
        videos,
        setFeaturedVideos,
        setOtherVideos,
      );
    };

    if (videosAreLoaded(loadVideos)) {
      setHelpVideos(loadVideos[0].sort(sortVideosByWeight));
      processVideos(helpVideos);
    }
  }, [helpVideos, loadVideos, setHelpVideos]);

  return (
    <>
      <PageHeader
        breadcrumbs={[{ href: '/', name: 'Help', current: true }]}
        homeInfo={{
          homeLink: 'https://platform.ftrprf.be',
          homeText: 'Platform'
        }}
        title={'Help'}
      />
      <div className="max-w-5/6 mx-auto mt-8 pb-8">
        <SearchBar
          className=""
          onChange={filterVideos}
          placeholder={"Wat zoek je?"}
        />
        {featuredVideos && Boolean(featuredVideos.length) && (
          <VideosSection
            destinationForSkiplink={'categorieën'}
            id="featured"
            skipToId="#categories"
            title={'Aanbevolen'}
          >
            <ul className="grid sm:gap-2 2xl:gap-6 sm:grid-cols-1 md:grid-cols-2  xl:grid-cols-videoFeatured">
              {featuredVideos.map((uitgelicht) => (
                <VideoItem
                  key={uitgelicht.id}
                  featured={true}
                  video={uitgelicht}
                  tagPrefix="uitgelichteTags"
                />
              ))}
            </ul>
          </VideosSection>
        )}
        {tags && Boolean(tags.length) && (
          <VideosSection
            destinationForSkiplink={'videos'}
            id="categories"
            skipToId="#videos"
            title={'Categorieën'}
          >
            <ul className="flex flex-wrap gap-4">
              {tags.map((tag, categoryIndex) => (
                <Category key={`category${categoryIndex}`} category={tag} />
              ))}
            </ul>
          </VideosSection>
        )}
        { helpVideos && Boolean(helpVideos.length) && (
          <section className="mt-8 mb-8" id="videos">
            <h2 className="w-full h-16 py-4 font-semibold text-2xl mt-2">
              {"Video's"}
            </h2>
            <ul className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
              {helpVideos &&
                otherVideos.map((video) => (
                  <VideoItem
                    key={video.id}
                    featured={false}
                    video={video}
                    tagPrefix="videoTag"
                  />
                ))}
            </ul>
          </section>
        )}
      </div>
    </>
  );
};
