import { generatePath, useNavigate } from 'react-router-dom';

import { approvedTags } from '../approvedTags';

export const Category = ({ category }) => {
  const tagObject = approvedTags[category] ?? approvedTags.default;
  const Icon = tagObject.icon;
  const navigate = useNavigate();
  const linkToDetail = generatePath('/tag/:category', { category: category });
  const goToCategory = () => {
    navigate(linkToDetail);
  };

  return (
    <li
      className="cursor-pointer hover:outline hover:outline-blue-500 hover:outline-2 focus:outline focus:outline-blue-500 focus:outline-2"
      onClick={goToCategory}
    >
      <div
        className={tagObject.bgColor + ' w-[9rem] h-32 flex items-center justify-center flex-col'}
      >
        <Icon className={tagObject.textColor + ' w-12'} />
        <h3
          className={'text-center font-semibold text-lg ' + tagObject.textColor}
        >
          {category}
        </h3>
      </div>
      <a className="sr-only" href={linkToDetail}>
        { category }
      </a>
    </li>
  );
};
