export const splitVideosIntoFeaturedAndOthers = (
  helpVideos,
  setFeaturedVideos,
  setOtherVideos,
) => {
  setFeaturedVideos(
    helpVideos.filter((video) => Boolean(video.data.uitgelicht)),
  );
  setOtherVideos(helpVideos.filter((video) => !video.data.uitgelicht));
};
