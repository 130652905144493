import { generatePath, useNavigate } from 'react-router-dom';
import { PrismicRichText, PrismicText } from '@prismicio/react';

import { DisclosureWidgetEllipsis, SRLink } from '@ftrprf/tailwind-components';

import c from '../../utils/c';
import URLS from '../../utils/constants/urls';

import { Tags } from './Tags';

export const VideoItem = ({ featured, video, tagPrefix }) => {
  const maxSizeClasses = featured ? 'sm:w-full md:w-[20rem] lg:w-[24rem]' : '';
  const navigate = useNavigate();
  const linkToDetail = generatePath(URLS.HELP_VIDEO, { videoSlug: video.uid });
  const goToDetail = () => {
    navigate(linkToDetail);
  };

  return (
    <li
      className={`w-full rounded-lg overflow-hidden grayscale-within hover:outline hover:outline-blue-500 hover:outline-4 focus:outline focus:outline-blue-500 focus:outline-2 border border-gray-300 cursor-pointer mb-4 ${maxSizeClasses}`}
      onClick={goToDetail}
      tabIndex={0}
    >
      <article
        className={`max-w min-h-[8.375rem] h-full${featured ? '' : ' flex'}`}
      >
        {video.data.video?.thumbnail_url && (
          <div
            className={c(
              'grayscale hover:grayscale-0 duration-300',
              `${
                featured ? 'w-[24rem] max-w-full h-[16rem]' : 'w-[40%] h-full'
              }`,
            )}
            style={{
              aspectRatio: `${video.data.video.thumbnail_width} / ${video.data.video.thumbnail_height}`,
              backgroundImage: `url("${video.data.video.thumbnail_url}")`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        )}
        <div className="p-3">
          <h3 className="w-full mb-4 flex items-center font-medium text-xl">
            <PrismicText field={video.data.title} />
          </h3>
          {Boolean(video.tags.length) && (
            <Tags tags={video.tags} prefix={tagPrefix} />
          )}
          {Boolean(video.data?.short_description.length) && (
            <DisclosureWidgetEllipsis
              limit={20}
              content={video.data.short_description}
              open={false}
              textKey="text"
            >
              <PrismicRichText
                field={video.data.short_description}
                components={{
                  paragraph: ({ children }) => (
                    <p className="mb-4">{children}</p>
                  ),
                }}
              />
            </DisclosureWidgetEllipsis>
          )}
          <SRLink href={linkToDetail} text={video.data.title[0].text} />
        </div>
      </article>
    </li>
  );
};
