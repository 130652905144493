import { TagIcon as IoPricetagOutline } from '@heroicons/react/outline';
import { TagIcon as IoPricetag } from '@heroicons/react/solid';

export const approvedTags = {
  ftrprf: {
    bgColor: 'bg-purple-100',
    colors: 'bg-purple-100 text-purple-800',
    icon: IoPricetag,
    textColor: 'text-purple-800',
  },
  Smartschool: {
    bgColor: 'bg-blue-100',
    colors: 'bg-blue-100 text-blue-800',
    icon: IoPricetagOutline,
    textColor: 'text-blue-800',
  },
  studio: {
    bgColor: 'bg-pink-100',
    colors: 'bg-pink-100 text-pink-800',
    icon: IoPricetag,
    textColor: 'text-pink-800',
  },
  Klassen: {
    bgColor: 'bg-yellow-100',
    colors: 'bg-yellow-100 text-yellow-800',
    icon: IoPricetagOutline,
    textColor: 'text-yellow-800',
  },
  test: {
    bgColor: 'bg-emerald-100',
    colors: 'bg-emerald-100 text-emerald-800',
    icon: IoPricetag,
    textColor: 'text-emerald-800',
  },
  toets: {
    bgColor: 'bg-green-100',
    colors: 'bg-green-100 text-green-800',
    icon: IoPricetagOutline,
    textColor: 'text-green-800',
  },
  tag: {
    bgColor: 'bg-sky-100',
    colors: 'bg-sky-100 text-sky-800',
    icon: IoPricetag,
    textColor: 'text-sky-800',
  },
  tag2: {
    bgColor: 'bg-orange-100',
    colors: 'bg-orange-100 text-orange-800',
    icon: IoPricetagOutline,
    textColor: 'text-orange-800',
  },
  tag3: {
    bgColor: 'bg-cyan-100',
    colors: 'bg-cyan-100 text-cyan-800',
    icon: IoPricetag,
    textColor: 'text-cyan-800',
  },
  tag4: {
    bgColor: 'bg-violet-100',
    colors: 'bg-violet-100 text-violet-800',
    icon: IoPricetagOutline,
    textColor: 'text-violet-800',
  },
  tag5: {
    bgColor: 'bg-lime-100',
    colors: 'bg-lime-100 text-lime-800',
    icon: IoPricetag,
    textColor: 'text-lime-800',
  },
  tag6: {
    bgColor: 'bg-amber-100',
    colors: 'bg-amber-100 text-amber-800',
    icon: IoPricetagOutline,
    textColor: 'text-amber-800',
  },
  tag7: {
    bgColor: 'bg-red-100',
    colors: 'bg-red-100 text-red-800',
    icon: IoPricetag,
    textColor: 'text-red-800',
  },
  tag8: {
    bgColor: 'bg-indigo-100',
    colors: 'bg-indigo-100 text-indigo-800',
    icon: IoPricetagOutline,
    textColor: 'text-indigo-800',
  },
  tag9: {
    bgColor: 'bg-teal-100',
    colors: 'bg-teal-100 text-teal-800',
    icon: IoPricetag,
    textColor: 'text-teal-800',
  },
  tag10: {
    bgColor: 'bg-fuchsia-100',
    colors: 'bg-fuchsia-100 text-fuchsia-800',
    icon: IoPricetagOutline,
    textColor: 'text-fuchsia-800',
  },
  default: {
    bgColor: 'bg-blue-100',
    colors: 'bg-blue-100 text-blue-800',
    icon: IoPricetagOutline,
    textColor: 'text-blue-800',
  },
};
