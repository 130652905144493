import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { HelpVideos } from "./HelpVideos/VideoOverview/HelpVideos";
import { HelpVideoDetail } from "./HelpVideos/VideoDetail/HelpVideoDetail";
import { HelpVideosByTag } from "./HelpVideos/VideoOverview/HelpVideosByTag";
import Dots from "./assets/dots.svg";

function App() {
  return (
    <div className="antialiased w-full h-full flex flex-col flex-grow items-center relative">
      <div className="w-full h-screen">
        <main className="h-full">
          <nav className="bg-white shadow border-b w-full" data-headlessui-state="">
            <div className="max-w-5/6 mx-auto w-full">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <a className="flex-shrink-0 flex items-center" href="/">
                    <img
                      className="block h-10 w-auto"
                      src={Dots}
                      alt="Future Proof"
                    />
                    <div className="ml-2 hidden lg:block">
                      <div className="text-gray-800 font-semibold tracking-widest leading-none">
                        <abbr
                          aria-hidden="true"
                          title="Future Proof"
                          className="border-none no-underline"
                        >
                          FTRPRF
                        </abbr>
                        <span className="sr-only">Future Proof</span>
                      </div>
                    </div>
                  </a>
                  <nav className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    <a
                      data-test="headerHome"
                      id="headlessui-disclosure-button-2"
                      data-headlessui-state=""
                      className="inline-flex items-center px-1 pt-1  text-sm font-medium border-accent-500 text-gray-900"
                      href="https://platform.ftrprf.be"
                    >
                      Terug naar het platform
                    </a>
                    <a
                      id="headlessui-disclosure-button-2"
                      data-headlessui-state=""
                      className="inline-flex items-center px-1 pt-1  text-sm font-medium border-accent-500 text-gray-900"
                      href="/"
                    >
                      Help
                    </a>
                  </nav>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center gap-1">
                  <a
                    href="https://new.studio.ftrprf.be"
                    title="Studio"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                  >
                    <span className="sr-only">Studio</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <Routes>
            <Route path="/">
              <Route index element={<HelpVideos />} />
              <Route path="tag/:category" element={<HelpVideosByTag />} />
              <Route path=":videoSlug" element={<HelpVideoDetail />} />
            </Route>
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
