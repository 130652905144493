export const VideosSection = ({
  children,
  destinationForSkiplink,
  id,
  skipToId,
  title,
}) => {
  return (
    <section className="my-4 relative" id={id}>
      {destinationForSkiplink ? (
        <a
        href={skipToId}
        className={`absolute p-2 top-0 bg-cyan-200 right-[200%] focus:left-1/2 focus:top-[2%] focus:right-[unset]`}
      aria-hidden={true}
    >
      {'Ga naar ' + destinationForSkiplink }
    </a>
      ) : null}
      <h2 className="w-full h-16 py-4 font-semibold text-2xl mt-2">{title}</h2>
      {children}
    </section>
  );
};
