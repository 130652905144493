export const titleContainsSearchQuery = (video, searchString) => {
  return video.data.title[0].text.toLowerCase().indexOf(searchString) >= 0;
};

export const tagsContainSearchQuery = (video, searchString) => {
  return Boolean(
    video.tags.filter((tag) => tag.toLowerCase().indexOf(searchString) >= 0)
      .length,
  );
};

export const descriptionContainsSearchQuery = (video, searchString) => {
  return Boolean(
    video.data.description.filter(
      (item) => item.text.toLowerCase().indexOf(searchString) >= 0,
    ).length,
  );
};

export const shortDescriptionContainsSearchQuery = (video, searchString) => {
  return Boolean(
    video.data.short_description.filter(
      (item) => item.text.toLowerCase().indexOf(searchString) >= 0,
    ).length,
  );
};

export const videoContainsSearchQuery = (video, searchString) => {
  return (
    titleContainsSearchQuery(video, searchString) ||
    tagsContainSearchQuery(video, searchString) ||
    descriptionContainsSearchQuery(video, searchString) ||
    shortDescriptionContainsSearchQuery(video, searchString)
  );
};
